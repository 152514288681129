/* Industry Zone */
.for_industry_zone {
  position: relative;
  padding: 40px 0px;
}
.industry_zone_head {
  padding: 24px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
}
.industry_zone_li {
  background: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.09);
  box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.09);
}
.industry_zone_title,
.industry_zone_caption {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #1b2127;
}
.industry_zone_caption {
  color: #77818c;
}
.industry_zone_body {
  padding: 24px 24px;
}
.industry_zone_li {
  margin-bottom: 16px;
}
.for_industry_zone_upload .preview_upload_img {
  height: 180px;
  position: relative;
}
.for_industry_zone_upload .preview_upload_img .uploaded_file_preview {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}
.for_industry_zone_upload .preview_img_detail {
  padding-right: 16px;
}
.for_industry_zone_upload.from_loop .preview_upload_img {
  width: 265px;
}
.from_loop .preview_img_detail {
  width: auto;
}
.edit_remove_div {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
}
.edit_remove_div img {
  cursor: pointer;
}
