/* Card */
.card_section {
  padding: 30px 0px;
}
.card_ul {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0px 0px 40px;
}
.card_ul .card {
  padding: 8px 8px 0px;
  border-radius: 8px;
  border: 0px;
  -webkit-box-shadow: 1px 1px 5px 0px #e3e3e3;
  box-shadow: 1px 1px 5px 0px #e3e3e3;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.card_ul .card:hover {
  -webkit-box-shadow: 1px 20px 30px 0px #e3e3e3;
  box-shadow: 1px 20px 30px 0px #e3e3e3;
}
.card_ul .card_img {
  border-radius: 15px;
  height: 160px;
}
.card_ul .view_event {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 124, 195, 0.5);
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.card_ul .view_event .MuiButton-outlined {
  text-align: center;
  border: 1px solid #ffff;
  border-radius: 4px;
}
.card_ul .view_event .MuiButton-label,
.card_ul .view_event .MuiButton-label a {
  color: #fff;
  text-align: center;
}

.card_ul .card-body {
  padding: 24px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.p-relative {
  position: relative;
}
.card-title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #1b2127;
  margin-bottom: 4px;
  padding-right: 10px;
}
.url_text {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #77818c;
  padding-bottom: 4px;
}
.url_text .copied_text_span {
  position: absolute;
  top: 42px;
  left: -15px;
  padding: 2px 8px;
  z-index: 1;
  background-color: rgba(119, 129, 140, 0.15);
  border-radius: 3px;
}
.url_text .copied_text_span:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgba(119, 129, 140, 0.15);
  left: 50%;
  top: -8px;
  -webkit-transform: rotate(90deg) translateX(-50%);
  -ms-transform: rotate(90deg) translateX(-50%);
  transform: rotate(90deg) translateX(-50%);
  margin-left: -3px;
}
.copy_icon {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.copy_icon img {
  width: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy_icon:hover {
  background: rgba(119, 129, 140, 0.15);
}
.status_dot {
  position: absolute;
  top: 7px;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  cursor: pointer;
}
.card .__react_component_tooltip {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 10px;
}
.draft,
.card .type-warning {
  background: #f0bd1a;
}
.published,
.card .type-success {
  background: #00bd72;
}
.archived,
.card .type-error {
  background: #ff5465;
}
.card .__react_component_tooltip.type-warning.place-top::after {
  border-top-color: #f0bd1a;
}
.card .__react_component_tooltip.type-success.place-top::after {
  border-top-color: #00bd72;
}
.card .__react_component_tooltip.type-error.place-top::after {
  border-top-color: #ff5465;
}
.date_text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #77818c;
  font-style: italic;
}
/* HOVER */
.card_ul .card:hover .MuiButtonBase-root.duplicate_btn .MuiButton-label {
  color: #fff;
}
.card_ul .card:hover .view_event {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.bg_cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #347359;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.short_name {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 30px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
}
.dropdown_dots {
  position: absolute;
  top: 5px;
  right: 12px;
}
.fixed_dropdown {
  position: absolute;
  top: 30px;
  right: 8px;
  background: #fff;
  -webkit-box-shadow: 1px 5px 30px rgba(56, 57, 58, 0.24);
  box-shadow: 1px 5px 30px rgba(56, 57, 58, 0.24);
  padding: 8px 24px;
  border-radius: 8px;
}
.fixed_dropdown a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.view_event_colunm {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0px 48px;
}
.view_event_colunm a {
  width: 100%;
}
.copied_text {
  position: absolute;
  top: 0;
}
/* background Color */
.card_li_0 .bg_cover {
  background: #0087cd;
}
.card_li_1 .bg_cover {
  background: #118681;
}
.card_li_2 .bg_cover {
  background: #59853f;
}
.card_li_3 .bg_cover {
  background: #2b754c;
}
.card_li_4 .bg_cover {
  background: #734a7b;
}
.card_li_5 .bg_cover {
  background: #793d4f;
}
.card_li_6 .bg_cover {
  background: #95473b;
}
.card_li_7 .bg_cover {
  background: #906341;
}
.card_li_8 .bg_cover {
  background: #787c44;
}
.card_li_9 .bg_cover {
  background: #596d7b;
}
.fixed_circle {
  position: absolute;
  bottom: 0;
  right: 0;
}
