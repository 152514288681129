.living_labs_btn .btn-primary,
.living_labs_btn .btn-primary:hover,
.living_labs_btn .btn-primary:focus,
.living_labs_btn .btn-primary:active,
.living_labs_btn .btn-primary:not(:disabled):not(.disabled).active,
.living_labs_btn .btn-primary:not(:disabled):not(.disabled):active,
.living_labs_btn .show > .btn-primary.dropdown-toggle,
.living_labs_btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.living_labs_btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.living_labs_btn .show > .btn-primary.dropdown-toggle:focus {
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 700;
  width: 100%;
  color: #1b2127;
  padding: 0px;
  text-align: left;
}
.living_labs_btn.for_dropdown .dropdown-toggle::after {
  position: relative;
  top: 0;
  left: 0;
}
.multiple-media-container{
  padding-top: 10px;
  display: flex;
  overflow: overlay;
}
.edit-experiences-section {
  position: relative;
  padding: 40px 0px;
}

.edit-experiences-section .MuiPaper-root.MuiAccordion-root {
  border-radius: 8px;
  border: 0;
  box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
  margin-bottom: 15px;
}
.edit-experiences-section .MuiAccordion-root:before {
  opacity: 0;
}
.edit-experiences-section .MuiAccordionSummary-root {
  padding: 24px 24px;
}
.edit-experiences-section .MuiAccordionSummary-content,
.edit-experiences-section .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px;
}
.edit-experiences-section .MuiAccordionDetails-root {
  padding: 24px 24px;
}
.edit-experiences-section .MuiCollapse-wrapper {
  border-top: 1px solid rgba(204, 206, 209, 0.5);
}
.accorion_title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #1b2127;
}
.for_textarea .MuiFormControl-root {
  width: 100%;
  height: 100%;
}
.for_textarea .MuiOutlinedInput-multiline {
  padding: 0px 0px;
  height: 100%;
}
.material_label_text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535b66;
}
.id_text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.event_active {
  padding: 24px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.event_showcase_status {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #535b66;
  margin-right: 16px;
}

/* Toggle */
.can-toggle {
  position: relative;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  color: #fff;
}
.can-toggle label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.can-toggle label .can-toggle__switch:before {
  font-family: "Roboto", sans-serif;
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  left: 60px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  width: 60px;
  padding: 0 0px;
  color: #1b2127;
  cursor: pointer;
}
.can-toggle label .can-toggle__switch:after {
  font-family: "Roboto", sans-serif;
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: #007cc3;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1),
    -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #fff;
  top: 0;
  left: 0px;
  width: 60px;
  line-height: 30px;
  border-radius: 30px;
  cursor: pointer;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #fff;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #fff;
}
.can-toggle label .can-toggle__switch {
  position: relative;
  height: 32px;
  flex: 0 0 122px;
  border-radius: 100px;
  -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #fff;
  border: 1px solid rgba(83, 91, 102, 0.3);
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
  transform: translate3d(65px, 0, 0);
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.living_labs_btn .dropdown-toggle::after {
  vertical-align: 5px;
  border-top: 7px solid;
  border-right: 7px solid transparent;
  border-bottom: 0;
  border-left: 7px solid transparent;
}
