.sign_in_form {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/src/assets/images/sign-in-bg.png") no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -9;
}
.logo_cover {
  width: 100%;
  text-align: center;
  padding: 40px 0px;
}
.signin_form {
  width: 400px;
  margin: 0 auto;
  background: #fff;
  -webkit-box-shadow: 1px 0px 100px 0px rgba(12, 13, 14, 0.2);
  box-shadow: 1px 0px 100px 0px rgba(12, 13, 14, 0.2);
  border-radius: 8px;
  padding: 40px 40px;
}
.redirect_link,
.redirect_link:hover,
.redirect_link:focus {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 16px;
  color: #1b2127;
  text-decoration: underline;
}
