.multi_select_dropdown .react-select__indicator-separator,
.multi_select_dropdown .react-select__indicators {
  display: none;
}
.multi_select_dropdown .react-select__control {
  height: 56px;
  border-color: rgba(83, 91, 102, 0.3);
  background: url("/src/assets/images/dropdown.svg") no-repeat;
  background-size: auto;
  background-position: 97%;
  overflow: auto;
}
.multi_select_dropdown .react-select__control--is-focused {
  border-color: rgba(83, 91, 102, 0.3) !important;
  box-shadow: none;
}
.multi_select_dropdown .react-select__value-container {
  height: 100%;
  padding: 0px 15px;
  overflow: auto;
}
.multi_select_dropdown .react-select__placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #535b66;
}
.multi_select_dropdown .react-select__multi-value {
  background-color: #e6e9ee;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #1b2127;
}
.multi_select_dropdown .react-select__multi-value__label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #1b2127;
}

/* Expand Collapse Icons */
.accoridon_section .MuiAccordionSummary-expandIcon .MuiIconButton-label {
  display: none;
}
.accoridon_section .MuiAccordionSummary-expandIcon {
  background: url("/src/assets/images/expand.svg") no-repeat;
}
.accoridon_section .MuiAccordionSummary-expandIcon.Mui-expanded {
  background: url("/src/assets/images/collapse.svg") no-repeat;
}
.accoridon_section .MuiIconButton-edgeEnd {
  margin-right: 0px;
}
.carosel_title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: "#1B2127";
  font-size: 16px;
}
.list_items_message .carosel_caption {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid rgba(83, 91, 102, 0.3);
  color: #1b2127;
  padding: 16px 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.carosel_close_icon img {
  width: 15px;
  cursor: pointer;
}
.placeholder_div .upload_input .preview_upload_img.for_video_upload {
  max-width: 400px;
  min-width: 300px;
  /* height: 100%; */
}
.presenter_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
  padding-bottom: 16px;
}
.placeholder_div .upload_input .preview_upload_img.for_video_upload audio {
  width: 250px;
}
.carousel_input {
  width: 100%;
  padding: 0px 10px;
  border: 0px;
}
