.published_label {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #00bd72;
  padding: 6px 12px 6px 24px;
  border-radius: 5px;
  background: rgba(0, 189, 114, 0.1);
  margin-left: 10px;
}
.published_label:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 6px;
  width: 10px;
  height: 10px;
  background: #00bd72;
  border-radius: 30px;
}
.event_bar_url {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #535b66;
  padding-top: 6px;
}
.view_event_right_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.wrapper .MuiButton-outlinedPrimary {
  border: 1px solid rgba(0, 124, 195, 0.4) !important;
  color: #007cc3;
}
.view_event_right_bar .MuiButton-containedSecondary:hover,
.view_event_right_bar .MuiButton-containedSecondary {
  background: #00bd72;
}
.MuiAvatar-colorDefault.avtar_bg_1,
.MuiAvatar-colorDefault.avtar_bg_2,
.MuiAvatar-colorDefault.avtar_bg_3,
.MuiAvatar-colorDefault.avtar_bg_4,
.MuiAvatar-colorDefault.avtar_bg_5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.MuiAvatarGroup-avatar {
  border: 2px solid #fff;
}
.MuiAvatar-colorDefault {
  background-color: #3e4853;
}
.MuiAvatar-colorDefault.avtar_bg_1 {
  background: #efdede;
  color: #7c5e5e;
}
.MuiAvatar-colorDefault.avtar_bg_2 {
  background: #c6f0eb;
  color: #496864;
}
.MuiAvatar-colorDefault.avtar_bg_3 {
  background: #daf0c3;
  color: #768666;
}
.MuiAvatar-colorDefault.avtar_bg_4 {
  background: #e8d1f8;
  color: #65586e;
}
.MuiAvatar-colorDefault.avtar_bg_5 {
  background: #f8e5b7;
  color: #786e56;
}

/* Event Section */
.event_setting_section {
  position: relative;
  padding: 40px 0px;
}
.List_experiences_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cover_tabel {
  width: 100%;
  padding-top: 40px;
}
.cover_tabel .MuiFormGroup-root.material-group {
  margin-bottom: 0px;
}
.cover_tabel .MuiIconButton-root.MuiCheckbox-root {
  padding: 0px 0px;
}
.cover_tabel .MuiFormControlLabel-root {
  margin: 0px 0px;
}
.table_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1b2127;
}
.table_title span {
  font-size: 14px;
  font-weight: 400;
}
.table_snapshot {
  width: 150px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px;
}
.tabel_caption {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.delete_icon,
.edit_icon {
  cursor: pointer;
}
.cover_tabel .table thead th,
.cover_tabel .table td {
  border: 0px;
  padding: 14px 18px;
}
.cover_tabel .table tbody tr {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(38, 42, 52, 0.09);
  box-shadow: 0px 2px 6px 0px rgba(38, 42, 52, 0.09);
  border-bottom: 12px solid #f0f5f7;
  border-radius: 8px;
}
.cover_tabel .table td {
  vertical-align: middle;
}
.cover_tabel .table tbody tr:last-child {
  border-bottom: 0px;
}
.event_setting_section .cover_tabel .table td:first-child {
  width: 150px;
}

.cover_tabel .btn-primary,
.cover_tabel .btn-primary:hover,
.cover_tabel .btn-primary:focus,
.cover_tabel .btn-primary:active,
.cover_tabel .btn-primary:not(:disabled):not(.disabled).active,
.cover_tabel .btn-primary:not(:disabled):not(.disabled):active,
.cover_tabel .show > .btn-primary.dropdown-toggle,
.cover_tabel .btn-primary:not(:disabled):not(.disabled).active:focus,
.cover_tabel .btn-primary:not(:disabled):not(.disabled):active:focus,
.cover_tabel .show > .btn-primary.dropdown-toggle:focus {
  background-color: transparent;
  border: 0px;
  outline: 0px;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1b2127;
  padding: 0px 0px;
}
.dropdown-toggle::after {
  margin-left: 15px;
}
.cover_tabel .dropdown-menu {
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 30px 0px #c0c7d1;
  box-shadow: 1px 5px 30px 0px #c0c7d1;
  border: 0px;
  padding: 0px 0px;
}
.cover_tabel .dropdown-item {
  padding: 16px 100px 16px 24px;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
}
.cover_tabel .dropdown-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.cover_tabel .dropdown-item:last-child {
  border-bottom: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* /images/selected.svg */
.cover_tabel .dropdown-item:hover {
  background: url("/src/assets/images/selected.svg") no-repeat;
  background-size: 20px;
  background-position: 92%;
}
.cover_tabel .dropdown-item:focus,
.cover_tabel .dropdown-item:hover,
.cover_tabel .dropdown-item:active {
  background-color: #eef9ff;
  color: #1b2127;
}

.list_exp_title {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #1b2127;
}
.wrapper .view_event_right_bar .MuiButton-label {
  text-transform: initial;
}
.wrapper .view_event_right_bar .MuiButton-outlinedSecondary {
  border: 1px solid rgba(255, 84, 101, 0.2);
}
.link_text,
.link_text:hover,
.link_text:focus,
.link_text:active {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #007cc3;
}

/* Table */
.cover_tabel .MuiSwitch-colorPrimary.Mui-checked {
  color: #007cc3;
}
.cover_tabel .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #e3e7ec;
  opacity: 1;
}
.cover_tabel .MuiFormControlLabel-label.MuiTypography-root {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: #535b66;
  padding-right: 12px;
}
.tabel_showcase_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
  margin-bottom: 4px;
}
.tabel_showcase_caption {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: #535b66;
  line-height: 1.2;
}
.edit_delete_tag {
  width: 50px;
  text-align: right;
  padding-right: 16px;
}
.ad-collaborators-header .popup_close_icon {
  position: absolute;
  top: 36px;
  right: 30px;
  cursor: pointer;
}
.popup_close_icon {
  cursor: pointer;
}
.ad-collaborators-modal .MuiButton-containedPrimary {
  padding: 8px 30px;
  background-color: #007cc3;
  border-color: #007cc3;
  text-transform: initial;
  box-shadow: none !important;
}
.ad-collaborators-header .MuiButtonBase-root.MuiButton-containedPrimary,
.ad-collaborators-header .MuiButtonBase-root.MuiButton-containedPrimary:hover,
.ad-collaborators-modal .MuiButton-containedPrimary:hover,
.ad-collaborators-modal .MuiButton-containedPrimary:focus {
  background-color: #007cc3;
  border-color: #007cc3;
}
.ad-collaborators-header .MuiButton-label {
  text-transform: initial;
}

/* Collaborators Tabel */
.table.collaborators_tabel_striped {
  margin: 0px 0px;
}
.modal-dialog .modal-body.collaborators_tabel {
  padding: 0px 0px;
}
.collaborators_tabel .table thead th {
  border: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1b2127;
  padding: 16px 32px;
}
.collaborators_tabel .table tbody tr td {
  padding: 16px 32px;
  border: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.collaborators_tabel .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f4f7;
}
.List_experiences_head .MuiInput-root {
  padding: 0px 75px 0px 6px;
  background: rgba(119, 129, 140, 0.1);
}
.delete_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}
.if_content_is_more {
  width: 25%;
}
.event_setting_section .cover_tabel .MuiFormControl-root {
  width: 100%;
}
.event_setting_section .cover_tabel .MuiSelect-select:focus {
  background-color: transparent;
}
/* .showcase_dropdown_width {
  position: relative;
  max-width: 250px;
  width: 250px;
}
.name_on_place {
  width: 250px;
} */

/* tabel alignment */
.layout_tabel {
  table-layout: fixed;
  width: 100%;
}
.layout_tabel td {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Column widths are based on these cells */
.row_name {
  width: 11%;
}
.row_id {
  width: 6%;
}
.row_showcases {
  width: 18%;
}
.row_placard {
  width: 15%;
}
.row_description {
  width: 20%;
}
.row_order {
  width: 8%;
}
.row_enabled {
  width: 13%;
}
.row_empty {
  width: 6%;
}
