.add_people_section,
.people_section {
  position: relative;
  padding: 40px 0px;
}
.cover_add_people {
  padding: 24px 24px;
  background-color: #fff;
  -webkit-box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
  box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
  border-radius: 8px;
}
.add_people_section .multi_select_dropdown .react-select__value-container {
  height: 100%;
  padding: 0px 14px;
}
.bootstrap_tabel {
  padding: 0px 0px;
  width: 100%;
  height: 432px;
}
.bootstrap_tabel .MuiDataGrid-root {
  border: 0px;
}
.bootstrap_tabel .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border: 0px;
}
.bootstrap_tabel .MuiDataGrid-root .MuiDataGrid-columnSeparator {
  display: none;
}
.bootstrap_tabel .MuiDataGrid-window {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px 0px rgba(38, 42, 52, 0.09);
  box-shadow: 0 2px 6px 0px rgba(38, 42, 52, 0.09);
  overflow-x: hidden !important;
}
/* For MediaLib Popup */
.default_assets {
  padding: 0px 0px 24px;
}
.for_media_library .media_library_ul {
  grid-template-columns: repeat(4, 222px);
  padding: 0px 0px 0px 0px;
  /* height: 330px; */
  grid-gap: 10px;
  overflow-x: hidden;
}
.for_media_library .media_library_ul.media_lib_with_height {
  height: 330px;
}
.for_media_library .media_library_item .cover_uploaded_img {
  height: 130px;
}
.for_media_library .media_library_ul .cover_uploaded_img img {
  height: 100%;
}
.upload-assets-popup .MuiChip-root {
  font-size: 14px;
  color: #1b2127;
  font-weight: 400;
  border-radius: 3px;
  background: #e6e9ee;
}
.upload-assets-popup .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-weight: 500;
  color: #26303c;
}
.upload-assets-popup .makeStyles-chips-6 {
  overflow-x: auto;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 6px;
}
.upload-assets-popup .makeStyles-chips-6::-webkit-scrollbar {
  height: 2px;
}
.upload-assets-popup .makeStyles-chips-6::-webkit-scrollbar-track {
  box-shadow: none;
}
.upload-assets-popup .makeStyles-chips-6::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 1px solid #ccc;
}
.upload_section .MuiButton-outlined,
.upload_section .MuiButton-containedPrimary {
  padding: 8px 24px;
  font-size: 16px;
  box-shadow: none;
}
.people_section .cover_tabel_area .table td {
  padding: 24px 24px;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
}
.people_section .cover_tabel_area .table thead th {
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
}
.or_text {
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 32px;
}
.or_text span {
  position: relative;
  width: 40px;
  height: 40px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  border-radius: 30px;
  color: rgba(62, 72, 83, 0.7);
  border: 1px solid #cbcdd1;
  background: #fff;
  padding: 10px 10px;
  z-index: 1;
}
.or_text:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(83, 91, 102, 0.2);
  z-index: 0;
}

/* Media Tag */
.media_tag_section {
  position: relative;
  padding: 40px 0px;
}
.media_tag_table .table-bordered {
  border: 0px;
  margin: 0px;
}
.published_btn {
  display: inline-block;
  border: 2px solid #00bd72;
  background: rgba(0, 189, 114, 0.1);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 16px;
  border-radius: 4px;
  color: #00bd72;
}
.media_tag_table .table td {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
  padding: 24px 24px;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
  word-break: break-all;
}
.media_tag_table .table tbody {
  background: #fff;
}
.media_tag_section .react-bootstrap-table-pagination {
  padding: 30px 0px 0px;
}
.react-bootstrap-table th.sortable {
  cursor: pointer;
}
.media_tag_section .dropup {
  position: relative;
}
.media_tag_section .react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}
.media_tag_section .dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid;
}
.media_tag_section .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.blocked_toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 5px;
}
.blocked_toggle .MuiFormControlLabel-root {
  margin-bottom: 0px;
}
/* For Label */
.td_label_width {
  width: 280px;
}
.td_label_width .tag_label {
  width: 100%;
}
.td_label_width .label_tag_span {
  margin-bottom: 8px;
}

.cover_tabel_area.media_tag_table p .table_img {
  width: 90px;
  height: 50px;
  object-fit: cover;
}
