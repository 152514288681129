.globa_setting_section {
  position: relative;
  width: 100%;
  padding: 40px 0px;
}
.global_link {
  font-family: "Roboto", sans-serif;
  display: block;
  padding: 24px 24px;
  -webkit-box-shadow: 0 2px 6px 0px rgba(38, 42, 52, 0.09);
  box-shadow: 0 2px 6px 0px rgba(38, 42, 52, 0.09);
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  color: #1b2127;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.global_link:hover {
  color: #1b2127;
}

/* Area */
.list_of_area_section {
  position: relative;
  width: 100%;
  padding: 40px 0px;
}
.areas_head {
  text-align: right;
}
.areas_head .area_link {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #007cc3;
}
.list_tabel {
  margin-top: 22px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(38, 42, 52, 0.09);
  box-shadow: 0 2px 6px rgba(38, 42, 52, 0.09);
}
.list_tabel {
  border-radius: 8px;
}
.cover_tabel_area .collaborators_tabel_striped {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.table_penel_title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #1b2127;
}
.table_panel_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.ad-area-modal {
  padding: 32px 32px 24px;
}
.checkbox_label {
  padding-top: 30px;
}
.checkbox_label .MuiFormGroup-root.material-group {
  margin-bottom: 0px;
}
.checkbox_label .MuiFormControlLabel-label.MuiTypography-root {
  color: #1b2127;
}
.table_img {
  width: 95px;
  border-radius: 3px;
}
.cover_tabel_area .table thead th {
  padding: 12px 24px;
  vertical-align: middle;
  border: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
.cover_tabel_area .table td {
  padding: 12px 24px;
  vertical-align: middle;
  border: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.cover_tabel_area .MuiFormControlLabel-label.MuiTypography-root {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.cover_tabel_area .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f4f7;
}

.list_of_area_section .cover_tabel_area .table td.description_td_width {
  width: 40%;
}
.list_of_area_section .cover_tabel_area .table td.checkbox_td {
  width: 20%;
}
.area_global_popup .modal-dialog .modal-body {
  padding-bottom: 12px;
}
.list_of_area_section .cover_tabel_area .table .area_img_td {
  width: 180px;
}
.list_of_area_section .cover_tabel_area .table .area_img_td .table_img {
  width: 100%;
}
