.MuiFormGroup-root.material-group {
  margin-bottom: 24px;
}
.material-group .MuiInputBase-root,
.material-group .MuiOutlinedInput-input,
.material-group .MuiFormLabel-root {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.material-group .MuiOutlinedInput-input {
  padding: 18px 16px;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535b66;
  background: #fff;
  margin: 0;
  line-height: normal;
  padding: 0px 8px 0px 4px;
}
.material-group .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(83, 91, 102, 0.3);
}
.material-group .MuiOutlinedInput-notchedOutline {
  padding: 0px 0px !important;
  border-color: rgba(83, 91, 102, 0.3);
}
.material-group .MuiFormLabel-root.Mui-focused {
  color: #007cc3;
}
.material-group
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: #007cc3;
}
/* For CheckBox */
.MuiIconButton-root.MuiCheckbox-root {
  padding: 0px 8px;
}
.MuiCheckbox-root {
  color: #007cc3 !important;
}
.MuiFormControlLabel-label.MuiTypography-root {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #626d79;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid transparent;
  -webkit-text-fill-color: #1b2127;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Primary btn */
.wrapper .signin_form .MuiButtonBase-root.MuiButton-containedPrimary,
.wrapper .signin_form .MuiButtonBase-root.MuiButton-containedPrimary:hover {
  color: #fff;
  background-color: #0daacf;
}
.wrapper .MuiButton-contained {
  box-shadow: none;
}
.event_bar_section .MuiButton-outlinedSecondary,
.media_uploader_section .MuiButton-outlinedSecondary {
  color: #ff5465;
  border: 1px solid#FF5465;
}

.wrapper .signin_form .MuiButton-root {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding: 12px 0px;
  text-transform: capitalize;
  outline: 0;
  box-shadow: none;
}
.material-group .MuiSelect-select:focus {
  background: transparent;
}

.error_msg {
  color: #fb0b0b;
  padding-top: 5px;
  margin: 0px 0px;
}
.event_box .radio_check_div .no_margin .MuiFormControl-root {
  margin-right: 0px;
}
.MuiDialog-scrollPaper .MuiInput-underline:after,
.MuiInput-underline:after {
  border-color: #007cc3 !important;
}
.MuiDialog-scrollPaper .MuiPickersToolbar-toolbar {
  background-color: #007cc3;
}
.MuiDialog-scrollPaper .MuiPickersDay-daySelected {
  background-color: #007cc3;
}
.MuiDialog-scrollPaper .MuiPickersDay-daySelected:hover {
  background-color: #007cc3;
}
.MuiDialog-scrollPaper .MuiButton-textPrimary {
  color: #007cc3;
}
.wrapper .placeholder_div .MuiButton-root {
  padding: 2px 24px;
}

.checkbox_label_primary .MuiFormControlLabel-label.MuiTypography-root {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1b2127;
}

.ad-area-modal.add-showcase-modal .MuiButton-containedPrimary,
.ad-area-modal.add-showcase-modal .MuiButton-containedPrimary:hover,
.ad-area-modal.add-showcase-modal .MuiButton-containedPrimary:focus {
  color: #fff;
  background-color: #007cc3;
  padding: 6px 30px;
}
.list_of_area_section .cover_tabel_area .table td {
  padding: 24px 24px;
}

/* Forgot Password */
.register_flow_title {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #1b2127;
  padding-bottom: 30px;
}
.instructions_text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535b66;
  padding-bottom: 24px;
  padding-right: 15px;
}
.wrapper
  .register_flow_form
  .signin_form
  .MuiButtonBase-root.MuiButton-containedPrimary {
  background: #007cc3;
  padding: 10px 30px;
}
.wrapper .register_flow_form .signin_form .MuiButton-outlinedPrimary {
  padding: 10px 30px;
}
.go_to_login a,
.go_to_login a:focus,
.go_to_login a:hover {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #007cc3;
}

.material-group
  .MuiOutlinedInput-notchedOutline
  .PrivateNotchedOutline-legendLabelled-3,
.material-group
  .MuiOutlinedInput-notchedOutline
  .PrivateNotchedOutline-legendLabelled-15,
.material-group
  .MuiOutlinedInput-notchedOutline
  .PrivateNotchedOutline-legendLabelled-27 {
  max-width: 0px !important;
}

.cover_tabel_area .MuiIconButton-colorPrimary {
  color: #797e84 !important;
}
.cover_tabel_area .MuiCheckbox-colorPrimary.Mui-checked {
  color: #007cc3 !important;
}
