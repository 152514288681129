/* Event Box */
.event_box {
  position: relative;
  width: 100%;
  margin-top: 40px;
  background: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
  box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
}
.event_box_pd {
  padding: 24px 24px;
}
.event_box .MuiFormControlLabel-label.MuiTypography-root {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
}
.radio_check_div {
  padding: 24px 24px;
  border-top: 1px solid rgba(204, 206, 209, 0.5);
}
.event_box .radio_check_div .MuiFormControl-root {
  margin-right: 10px;
}
.event_box .radio_check_div .MuiFormControlLabel-root {
  margin-bottom: 0px;
}
.upload_input {
  position: relative;
  padding: 24px 16px;
  border: 1px solid rgba(83, 91, 102, 0.3);
  border-radius: 3px;
}
.dragdrop_box {
  width: 265px;
  text-align: center;
  background: #e3eaed;
  padding: 36px 36px;
  border-radius: 5px;
}
.dragdrop_box_text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #80949a;
  padding-top: 16px;
  line-height: 1.2;
}
.uploaded_file_preview {
  width: 265px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px;
}
.preview_img_detail {
  width: calc(100% - 265px);
  padding-left: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.uploaded_file_name {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #1b2127;
  word-break: break-all;
}
.maximum_size {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #80949a;
  margin-bottom: 8px;
}
.tabs_body {
  padding: 24px 24px;
}

/* Tabs */
.event_box_2 {
  background: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
  box-shadow: 1px 20px 30px 0px rgba(40, 44, 49, 0.05);
  padding: 0px 0px;
  margin: 24px 0px;
}
.tabs_header {
  padding: 24px 24px;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
}
.tabs_header .react-tabs__tab-list {
  border: 0px;
  margin: 0px 0px;
  padding: 0px 0px 12px;
}
.tabs_header .react-tabs__tab--selected {
  border-radius: 0px;
  border: 0px;
  padding: 0px 50px 0px 0px;
}
.tabs_header .react-tabs__tab--selected .circle_span {
  position: relative;
  background: #007cc3;
  border-color: #007cc3;
}
.tabs_header .react-tabs__tab--selected .circle_span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50px;
}
.tabs_header .react-tabs__tab {
  padding: 0px 50px 0px 0px;
}
.tabs_header .react-tabs__tab:last-child {
  padding: 0px 0px 0px 0px;
}
.tabs_title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tabs_header .react-tabs__tab:focus {
  box-shadow: none;
}
.tabs_header .circle_span {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(83, 91, 102, 0.3);
  border-radius: 50px;
  margin-right: 15px;
}
.common_text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535b66;
  font-style: italic;
}
.new_create_event {
  padding-bottom: 30px;
}
