.navbar {
  padding: 10px 48px;
}
.navbar.bg-dark {
  background-color: #26303c !important;
}
.navbar-nav {
  padding-left: 10px;
}
.navbar-dark .navbar-nav .nav-link {
  padding: 0px 30px 0px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #d3dae1;
}
.navbar-text {
  cursor: pointer;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #d3dae1;
}
.navbar-dark .navbar-nav .nav-link.active {
  font-weight: 700;
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:last-child {
  padding-right: 0px;
}
.setting_icon {
  cursor: pointer;
}

/* Navbar Dropdown */
.user_info.btn-success,
.user_info.btn-success:focus,
.user_info.btn-success:hover,
.user_info.btn-success:active,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #d3dae1;
}
.dropdown_box .dropdown-menu {
  padding: 0px 0px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 5px 30px 0px rgba(56, 57, 58, 0.24);
  box-shadow: 1px 5px 30px 0px rgba(56, 57, 58, 0.24);
  border: 0px;
  left: -138px;
  top: 0;
}
.user_name {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #1b2127;
}
.user_email {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #535b66;
  word-break: break-word;
}
.dropdown-items li {
  padding: 16px 24px;
  cursor: pointer;
}
.navbar .dropdown_box .dropdown-items li {
  min-width: 260px;
}
.dropdown-divider {
  margin: 0px 0px;
  border-color: #ccced1;
}
.dropdown-items li .nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
  padding: 0px 0px;
}
.cover_clickable .nav-link.navbar-text {
  padding: 16px 24px;
}
