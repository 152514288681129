@import url("https://fonts.googleapis.com/css?family=Satisfy");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  position: relative;
  /* min-height: 100%; */
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535b66;
  scroll-behavior: smooth;
  overflow-x: hidden;
  z-index: 1;
  background: #f0f5f7;
}
ul,
ol {
  list-style: none;
  margin: 0px;
}
a:focus,
a:hover {
  text-decoration: none;
  outline: 0px;
}
:focus {
  outline: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
button:focus {
  outline: 0px;
}

/* Primary Btn */
.wrapper .MuiButtonBase-root.MuiButton-containedPrimary,
.wrapper .MuiButtonBase-root.MuiButton-containedPrimary:hover {
  background: #007cc3;
  border-radius: 4px;
}
.wrapper .for_people .MuiButtonBase-root.MuiButton-containedPrimary:hover {
  color: #fff;
}
.wrapper .MuiButton-containedPrimary a,
.wrapper .MuiButton-containedPrimary a:hover,
.wrapper .MuiButton-containedPrimary a:focus {
  color: inherit;
}
.wrapper .MuiButton-label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}
.wrapper .MuiButton-root {
  padding: 6px 24px;
}
.wrapper .MuiButton-outlinedSecondary {
  margin-right: 10px;
}

/* Loader */
.fixed_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.fixed_loader .loader img {
  width: 60px;
}

/* BreadCrumb */
.breadcrumb {
  padding: 10px 80px;
  margin: 0px 0px;
}
.breadcrumb-item a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #007cc3;
}
.breadcrumb-item.active {
  color: #5b6673;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #007cc3;
  color: #007cc3;
  display: block;
  padding: 0;
  margin: 8px 8px 0px 0px;
}
.no_event {
  font-size: 20px;
  text-align: left;
}

/* Popup */
.modal-dialog .modal-header {
  padding: 32px 32px;
  border-bottom: 0px;
}
.modal-dialog .modal-body {
  padding: 0px 32px;
}
.popup_title {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #1b2127;
}
.bg_color {
  background-color: #f0f5f7;
}

.for_video_upload video {
  width: 100%;
  height: 100%;
}

.fixed_bottom_bg {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}

/* delete popup */
.delete_confirmation_popup .modal-dialog .modal-body {
  padding: 0px 32px 40px;
}
.confirmation_text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 32px;
  color: #1b2127;
}
.cover_delete_confirmation .MuiButton-outlinedPrimary,
.cover_delete_confirmation .MuiButton-outlinedPrimary:hover,
.cover_delete_confirmation .MuiButton-outlinedPrimary:focus {
  box-shadow: none;
  color: #007cc3;
  border-color: #007cc3;
  text-transform: initial;
  padding: 12px 30px;
}
.cover_delete_confirmation .MuiButton-containedSecondary,
.cover_delete_confirmation .MuiButton-containedSecondary:hover,
.cover_delete_confirmation .MuiButton-containedSecondary:focus {
  background-color: #ff5465;
  color: #fff;
  box-shadow: none;
  text-transform: initial;
  padding: 13px 30px;
}
.cover_delete_confirmation .MuiButton-label {
  line-height: initial;
}

/* input withoutborder */
.without_border .MuiInput-underline:hover:not(.Mui-disabled):before,
.without_border .MuiInput-underline:before,
.without_border .MuiInput-underline:after {
  border: 0px;
}
.material-group.without_border .MuiInputBase-root,
.material-group.without_border .MuiSelect-select,
.material-group.without_border .MuiFormLabel-root {
  font-size: 26px;
  font-weight: 600;
  color: #1b2127;
  margin: 0px;
}
.multi_select_title_drop {
  font-size: 26px;
  font-weight: 600;
  color: #1b2127;
  margin: 0px;
}
.cover_multi_input .without_border .MuiFormControl-root {
  margin: 0px;
}
.cover_multi_input .without_border .MuiSelect-iconOutlined {
  top: -28px;
}
.ad-collaborators-modal
  .cover_multi_input
  .without_border
  .MuiSelect-iconOutlined {
  top: 28px;
}
.ad-collaborators-modal
  .cover_multi_input
  .material-group.without_border
  .MuiSelect-select.MuiSelect-select {
  border: 1px solid rgba(83, 91, 102, 0.2);
  margin-top: 18px;
  margin-bottom: 6px;
}
/* .material-group.without_border .MuiSelect-select.MuiSelect-select {
  padding: 24px 0px 0px;
} */
.material-group.without_border .makeStyles-chips-10,
.material-group.without_border .makeStyles-chips-14 {
  height: 70px;
}
.material-group.without_border .MuiChip-root {
  color: #1b2127;
  font-size: 14px;
  font-weight: 400;
  border-radius: 3px;
  background-color: #e6e9ee;
}
.fixed_radio_top {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.fixed_radio_top .radio_btn {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Genral Css */
.full_clickable .MuiButtonBase-root.MuiIconButton-root {
  position: static;
}
.full_clickable .MuiButtonBase-root.MuiIconButton-root:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
}
.full_clickable .MuiButtonBase-root.MuiIconButton-root .MuiTouchRipple-root {
  display: none;
}
/* For Multiline */
.MuiInputBase-multiline.MuiOutlinedInput-multiline {
  padding: 18px 16px;
}
.MuiInputBase-multiline.MuiOutlinedInput-multiline .MuiOutlinedInput-input {
  height: 146px !important;
  padding: 0px 0px;
}

.border_top {
  border-top: 1px solid rgba(204, 206, 209, 0.5);
}
.border_top_hr {
  margin: 0px;
  border-color: rgba(204, 206, 209, 0.5);
}
.border_bottom_hr {
  border-bottom: 1px solid;
  border-color: rgba(204, 206, 209, 0.5);
}
.for_middle_hr {
  padding: 24px 0px 0px;
  width: 100%;
}
.for_textarea
  .MuiInputBase-multiline.MuiOutlinedInput-multiline
  .MuiOutlinedInput-input {
  height: auto !important;
}
.material_label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  padding: 0px 8px;
  font-size: 14px;
  color: #535b66;
  -webkit-transform: translate(-8px, 0px) scale(0.75);
  -ms-transform: translate(-8px, 0px) scale(0.75);
  transform: translate(-8px, 0px) scale(0.75);
}
.wrapper .btn_green.MuiButton-containedPrimary,
.wrapper .btn_green.MuiButton-containedPrimary:focus,
.wrapper .btn_green.MuiButton-containedPrimary:hover,
.wrapper .btn_green.MuiButton-containedPrimary:active,
.btn_green {
  background-color: #00bd72;
}
.wrapper .warning_btn.MuiButton-outlinedSecondary {
  color: #e59d00;
  border: 1px solid#E59D00;
}

.wrapper .MuiInputBase-input.Mui-disabled,
.wrapper .MuiInputBase-root.Mui-disabled,
.wrapper .MuiButtonBase-root.MuiButton-containedPrimary.Mui-disabled {
  background: #f6f7f9;
  cursor: not-allowed;
  color: #8d8f93 !important;
}

.preview_upload_img {
  position: relative;
  height: 180px;
}
.close_upload_span {
  position: absolute;
  top: 8px;
  right: 6px;
  cursor: pointer;
}
.MuiDialog-paper .MuiPickerDTTabs-tabs {
  background-color: #007cc3;
}
.PrivateTabIndicator-colorSecondary-7 {
  background-color: #ff5465 !important;
}
.MuiPickersClock-pin {
  background-color: #007cc3 !important;
}
.MuiPickersClockPointer-pointer {
  background-color: #007cc3 !important;
}
.MuiPickersClockPointer-thumb {
  border: 14px solid #007cc3 !important;
}
.pagination.react-bootstrap-table-page-btns-ul {
  float: right;
}
.pagination.react-bootstrap-table-page-btns-ul .page-item.active .page-link {
  background-color: #007cc3;
  border-color: #007cc3;
  color: #f9fafa;
}
.pagination.react-bootstrap-table-page-btns-ul .page-link:focus {
  box-shadow: none;
}
.pagination.react-bootstrap-table-page-btns-ul .page-link {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1b2127;
}
.full_clickable .MuiFormHelperText-contained {
  margin: 0px 0px;
  padding: 10px 0px 0px;
}
.no_result_found_title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}
.audio_bg {
  background: #e3eaed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.event_box .timezone_field .MuiFormControl-root {
  margin-right: 0px;
}

.category_span {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #6d7784;
  padding: 4px 6px;
  background: #e6e9ee;
  border-radius: 3px;
}
.category_label {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #6d7784;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background-color: transparent;
  border: 1px solid #e9ecef;
  box-shadow: none;
  color: #1b2127;
}
.react-bootstrap-table-pagination .btn-secondary,
.react-bootstrap-table-pagination .btn-secondary:focus,
.react-bootstrap-table-pagination .btn-secondary:hover {
  background-color: transparent;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1b2127;
  border: 1px solid #e9ecef;
}
.cover_tabel_area .table .MuiFormControlLabel-root {
  margin-bottom: 0px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.filter_by_div_control {
  max-width: 300px;
}
.filter_by_div_control .MuiSelect-selectMenu {
  white-space: normal;
}
.fixed_eye_icon {
  position: absolute;
  top: 15px;
  right: 35px;
  cursor: pointer;
}
.delete_confirmation_popup {
  background: rgba(0, 0, 0, 0.1);
}
.delete_popup_open {
  opacity: 0.2;
}
.upload_assets_media {
  width: 100%;
  height: 100%;
}
.from_upload_assets {
  height: 180px;
}
.MuiFormControl-root.cover_with_button {
  border: 1px solid rgba(83, 91, 102, 0.3);
}

/* material_with button input */
.material-group
  .MuiFormControl-root.cover_with_button
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.material-group
  .MuiFormControl-root.cover_with_button
  .MuiOutlinedInput-notchedOutline {
  border: 0px;
}
.cover_text_speech_btn {
  padding: 12px 0px 0px 0px;
  text-align: right;
}
.MuiFormControl-root.cover_with_button
  .MuiInputBase-multiline.MuiOutlinedInput-multiline {
  padding: 18px 16px;
}
.MuiFormControl-root.cover_with_button .MuiButtonBase-root.Mui-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.wrapper .add_tag .MuiButton-label {
  font-size: 14px;
  white-space: normal;
  line-height: 1.2;
}
.wrapper .add_tag .MuiButton-root {
  padding: 6px 16px;
}

/* Notification Message */
.app-container .notification-container-top-right {
  position: fixed;
  top: 8px;
  left: 0;
  right: 0px;
  max-width: 1650px;
  width: 100%;
  z-index: 999;
  margin: 0 auto;
}
.app-container
  .notification-container-top-right
  .notification-item
  .notification-message {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.2px;
  color: #fff;
}
.app-container
  .notification-container-top-right
  .notification-item
  .notification-close:after {
  font-size: 32px;
  padding: 0px 15px;
}
.app-container .notification-container-top-right .notification-item {
  padding: 10px 0px;
  border-radius: 0px;
  border-left: 0px solid;
  margin-bottom: 0px;
  border-radius: 8px;
  box-shadow: none;
}
.app-container .notification-item .timer,
.app-container .notification-item .timer .timer-filler {
  position: absolute;
  visibility: hidden;
}
.app-container .notification-danger,
.app-container .notification-danger .timer,
.app-container .notification-item.notification-success .notification-close {
  background-color: #da212d;
}
.app-container .notification-success,
.app-container .notification-success .timer,
.app-container .notification-item.notification-success .notification-close {
  background-color: #00b288;
}
.app-container .notification-item .notification-content {
  padding: 8px 32px;
}
.app-container .notification-item .notification-close {
  right: 30px;
  top: 22px;
  background-color: #da212d;
}
.app-container .notification-parent {
  margin-bottom: 15px;
}
.collaborator_admin_msg {
  width: 100%;
  padding: 10px 110px;
  background-color: #da212d;
}
.collaborator_admin_msg.event_user_info {
  background-color: #007cc3;
}
.collaborator_admin_caption,
.collaborator_admin_caption a,
.collaborator_admin_msg.event_user_info ol,
.collaborator_admin_msg.event_user_info a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #fff !important;
  cursor: default;
}
.collaborator_admin_caption a,
.collaborator_admin_msg.event_user_info a {
  cursor: pointer;
}
.collaborator_admin_msg.event_user_info ol {
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root {
  white-space: initial;
}
.create_showcase_title {
  color: #da212d;
}
.selectedImage {
  background: rgba(226, 233, 236, 0.7);
  border-radius: 5px;
}
.for_media_library .media_library_item .fixed_radio_top {
  opacity: 0;
}
.for_media_library .media_library_ul::-webkit-scrollbar {
  width: 6px;
}
.for_media_library .media_library_ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px transparent;
}
.for_media_library .media_library_ul::-webkit-scrollbar-thumb {
  background-color: #c2cbd6;
  outline: 0px solid transparent;
  height: 50px;
  border-radius: 3px;
}
.wrapper .cover_text_speech_btn .MuiButton-label {
  font-size: 14px;
}
.wrapper .cover_text_speech_btn .MuiButton-root {
  padding: 4px 12px;
}
.readMoreText {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #007cc3 !important;
  margin-left: 6px;
}
.event_url_base {
  padding-right: 15px;
}
.video_bg_cover {
  width: 100%;
  height: 100%;
}
.cover_tabel_area.media_tag_table .MuiFormControlLabel-root {
  margin-right: 0px;
}
.accorion_title.with_info_icon {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.accorion_title.with_info_icon img {
  margin-left: 8px;
}
.field_info_text {
  width: fit-content;
  margin: 8px 0;
  cursor: pointer;
}
.cover_text_speech_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.offline_now {
  width: 100%;
  padding: 10px 110px;
  background-color: #da212d;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
