.event_dashboard {
  position: relative;
  width: 100%;
  padding: 0px 0px;
}
.dropdowns_picker {
  padding: 40px 0px 24px;
}
.filter_div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.filter_text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #535b66;
}
/* Button Filter */
.filter_div .btn-primary,
.filter_div .btn-primary:hover,
.filter_div .btn-primary:focus,
.filter_div .btn-primary:active,
.filter_div .btn-primary:not(:disabled):not(.disabled).active,
.filter_div .btn-primary:not(:disabled):not(.disabled):active,
.filter_div .show > .btn-primary.dropdown-toggle,
.filter_div .btn-primary:not(:disabled):not(.disabled).active:focus,
.filter_div .btn-primary:not(:disabled):not(.disabled):active:focus,
.filter_div .show > .btn-primary.dropdown-toggle:focus {
  position: relative;
  background-color: rgba(119, 129, 140, 0.1);
  border: 0px;
  outline: 0px;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #26303c;
  padding: 6px 50px 6px 8px;
  border-radius: 3px;
}
.for_dropdown .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
}
.for_dropdown .dropdown-menu {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 5px 30px 0px #c0c7d1;
  border: 0px;
  padding: 0px 0px;
}
.for_dropdown .dropdown-item {
  position: relative;
  padding: 16px 100px 16px 24px;
  border-bottom: 1px solid rgba(204, 206, 209, 0.5);
}
.for_dropdown .dropdown-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #007cc3;
  opacity: 0;
}
.for_dropdown .dropdown-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.for_dropdown .dropdown-item:last-child {
  border-bottom: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* /images/selected.svg */
.for_dropdown .dropdown-item:hover {
  background: url("/src/assets/images/selected.svg") no-repeat;
  background-size: 20px;
  background-position: 92%;
}
.for_dropdown .dropdown-item:hover::before {
  opacity: 1;
}
.for_dropdown .dropdown-item:focus,
.for_dropdown .dropdown-item:hover,
.for_dropdown .dropdown-item:active {
  background-color: #eef9ff;
  color: #1b2127;
}
.for_event_page.card_ul .card_img,
.card_ul.for_event_page .view_event {
  border-radius: 8px;
}
