/* Event Bar */
.event_bar_section {
  padding: 30px 0px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(38, 42, 52, 0.09);
  box-shadow: 0px 2px 6px 0px rgba(38, 42, 52, 0.09);
}
.events_cover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.form_group {
  position: relative;
}
.form_input_search {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: 0px;
  padding: 0px 30px;
  color: rgba(27, 33, 39, 0.7);
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.event_search_icon {
  position: absolute;
  top: 0px;
  left: 0px;
}
.events_title {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 700;
  padding-right: 50px;
  color: #1b2127;
}
