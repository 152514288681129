@media screen and (max-width: 767px) {
  .signin_form {
    padding: 30px 20px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cover_uploaded_img img {
    height: 120px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .cover_uploaded_img img {
    height: 120px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .wrapper .view_event_right_bar .MuiButton-label {
    line-height: 1.2;
    white-space: nowrap;
  }
  .url_span {
    display: block;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 100%;
    padding: 0px 80px;
  }
  .modal-xl {
    width: 1000px;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1170px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1480px) {
}

@media screen and (min-width: 1599px) {
  .signin_form {
    width: 480px;
  }
  .card_ul {
    -ms-grid-columns: 1fr 28px 1fr 28px 1fr 28px 1fr 28px 1fr;
    grid-template-columns: repeat(5, 1fr);
    gap: 28px;
  }
  .view_event_colunm {
    padding: 0px 70px;
  }
  .card_ul .card_img {
    height: 190px;
  }
  .media_library_item .cover_uploaded_img {
    height: 260px;
  }
  .container-fluid {
    max-width: 100%;
    padding: 0px 110px;
  }
  .breadcrumb {
    padding: 10px 110px;
  }
  .if_content_is_more {
    width: 40%;
  }
  .preview_upload_img {
    height: 180px;
  }
  .list_of_area_section .cover_tabel_area .table td.description_td_width {
    width: 50%;
  }
  .for_media_library .media_library_ul.media_lib_with_height {
    height: 400px;
  }
  /* .for_media_library .media_library_ul {
    height: 400px;
  } */
  .flex_end_footer.modal_footer {
    padding: 32px 0px 32px;
  }

  /* tabel view event */
  .table_snapshot {
    height: 84px;
  }
  .row_name {
    width: 12%;
  }
  .row_id {
    width: 8%;
  }
  .row_showcases {
    width: 18%;
  }
  .row_placard {
    width: 15%;
  }
  .row_description {
    width: 20%;
  }
  .row_order {
    width: 10%;
  }
  .row_enabled {
    width: 12%;
  }
  .row_empty {
    width: 6%;
  }
  .wrapper .add_tag .MuiButton-label {
    white-space: nowrap;
  }
  .media_library_ul {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .card_ul {
    grid-template-columns: repeat(4, 1fr);
  }
  .media_library_item .cover_uploaded_img {
    height: 200px;
  }
}
