.media_uploader_section {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}
.modal_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px 0px 24px;
  border-top: 1px solid #ccced1;
}
.flex_end_footer.modal_footer {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.modal_footer.empty_dropzone {
  border-top: 0px solid #ccced1;
}
.modal_footer .MuiButton-containedPrimary {
  background: #007cc3;
}
.modal_footer .MuiButton-label {
  text-transform: capitalize;
}
.modal_footer .MuiButton-outlinedPrimary {
  border: 1px solid #007cc3;
  color: #007cc3;
}
.brows_files {
  margin-bottom: 15px;
}

/* Popup */
.drag_drop_div {
  width: 100%;
  padding: 100px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(227, 234, 237, 0.68);
  border: 3px dashed #afb9bd;
}
.drag_drop_title {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: rgba(123, 138, 156, 0.8);
  padding: 24px 0px;
}
.drag_drop_div .MuiButton-containedPrimary,
.upload-assets-popup .modal_footer .MuiButton-containedPrimary {
  background: #007cc3;
  text-transform: initial;
}
.upload_item {
  position: relative;
  margin-bottom: 24px;
}
.cover_uploaded_img img {
  width: 100%;
  height: 140px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}
.uploader_name {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1b2127;
  padding: 10px 0px 0px;
  word-break: break-all;
}
.uploader_size {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #77818c;
}
.remove_item_icon {
  position: absolute;
  top: 6px;
  right: 20px;
  cursor: pointer;
}

.selected_assets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px;
}
.selected_assets_title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #1b2127;
}
.selected_assets_caption {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #77818c;
}
.selected_assets .MuiButton-outlinedPrimary {
  border-color: #007cc3;
  color: #007cc3;
  text-transform: initial;
}
aside.cover_middle_libaray {
  height: 300px;
  overflow-x: hidden;
}

/* media_library */
.media_library_ul {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 0px 0px;
}
.uploader_file_size {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #77818c;
  padding: 4px 0px;
}
.tag_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.label_tag_span {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #6d7784;
  padding: 4px 6px;
  background: #d8dee1;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 5px;
}
.cover_uploaded_img {
  position: relative;
  border: 1px solid #fff;
  border-radius: 8px;
}
.file_type {
  position: absolute;
  bottom: 6px;
  right: 6px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  padding: 4px 6px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.filter_by_control {
  width: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.filter_text_caption {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #535b66;
  margin-right: 8px;
}
.media_library_head {
  padding: 30px 0px 20px;
}
.dropdowns_picker .MuiInput-root,
.media_library_head .MuiInput-root,
.for_media_library .MuiInput-root {
  padding: 0px 0px 0px 6px;
  background: rgba(119, 129, 140, 0.1);
  border-radius: 3px;
}
.filter_by_control .MuiSelect-select.MuiSelect-select,
.dropdowns_picke .MuiSelect-select.MuiSelect-select,
.media_library_head .MuiSelect-select.MuiSelect-select {
  padding-right: 100px;
}

.filter_by_control .MuiInput-underline:before,
.filter_by_control .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0px;
}
.filter_by_control .MuiSelect-select:focus {
  background: transparent;
}
.selected_assets_caption {
  color: #26303c;
}
.selected_assets .selected_assets_caption {
  color: #77818c;
}
.checkbox_label_fixed {
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 0px 0px;
}
.checkbox_label_fixed .MuiIconButton-root.MuiCheckbox-root {
  padding: 10px 18px;
}
.checkbox_label_fixed .MuiIconButton-root.MuiCheckbox-root::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.checkbox_label_fixed::after {
  content: "";
  position: absolute;
  left: auto;
  right: 20px;
  background: rgba(255, 255, 255, 0.65);
  width: 20px;
  height: 20px;
  z-index: 0;
  top: 12px;
  border-radius: 3px;
}
.checkbox_label_fixed .MuiIconButton-colorPrimary {
  position: relative;
  z-index: 1;
}
.checkbox_label_rel .MuiIconButton-root.MuiCheckbox-root {
  padding: 0px 0px;
}
.drag_drop_div .MuiButton-containedPrimary:hover,
.drag_drop_div .MuiButton-containedPrimary:focus,
.drag_drop_div .MuiButton-containedPrimary:hover,
.drag_drop_div .MuiButton-containedPrimary:focus {
  background: #007cc3;
}
.upload-assets-popup .modal_footer .MuiButton-outlinedPrimary {
  border-color: rgba(0, 124, 195, 0.4);
}
.tag_showcase .MuiFormGroup-root.material-group {
  max-width: 250px;
}
.makeStyles-chips-10,
.makeStyles-chips-14 {
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}
.media_library_item .cover_uploaded_img {
  height: 220px;
}
.media_library_item .media_video_lib {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}
.media_library_ul .cover_uploaded_img img {
  height: 100%;
}
.media_library_item {
  position: relative;
  cursor: pointer;
  padding: 8px;
}

/* Preview Popup */
.preview_img {
  position: relative;
  width: 100%;
}
.preview_img video {
  width: 100%;
}
.preview_img img {
  width: 100%;
  height: 320px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}
.edit_preview_popup .modal-lg {
  max-width: 554px;
}
.cover_audio_bg {
  height: 100%;
  background: #e2eaed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}
.edit_preview_popup .cover_audio_bg {
  height: 30vh;
  padding: 0px 15px;
}
.edit_preview_popup .cover_audio_bg .media_video_lib {
  width: 100%;
}
.video_bg_media_lib {
  height: 100%;
}
.edit_preview_popup .for_video_lib,
.video_bg_media_lib .media_video_lib {
  height: 100%;
}
